import {
  Component,
  OnInit,
  Inject,
  HostListener,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/apiservices.service";
import { NumberListResp, OpTypeRespData } from "../../enums/apiResponse";
import {
  OpTypes,
  OpTypesNames,
  RespCode,
  SessionVar,
} from "src/app/enums/emums";
import { ApidataService } from "src/app/services/apidata.service";
import { AuthService } from "src/app/services/auth.service";
import { MobileComponent } from "../pagecontainer/mobile/mobile.component";

@Component({
  selector: "aditya-menus",
  templateUrl: "./menus.component.html",
  styleUrls: ["./menus.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenusComponent implements OnInit {
  screenWidth: number;
  isMobile = false;
  isMenu = false;
  id = 1;
  BaseData: NumberListResp;
  menus: any;
  dropmenu: any;
  isMenuLoaded = false;
  Optypelst: OpTypeRespData;
  constructor(
    private router: Router,
    private apiServices: ApiService,
    private apiData: ApidataService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    // this.IsMobile = apiData.checkIsMobile();
  }
  ngOnInit() {
    let tab = localStorage.getItem("tabId");
    if (tab) {
      this.id = +tab;
    } else {
      this.id = 1;
    }
    console.log("on tab change using localStorage", tab);
    this.apiData.ScreenSize.subscribe((value) => {
      if (value > 250 && value <= 1024) {
        this.isMobile = true;
        this.apiData.isMobileScreen$.next(this.isMobile);
      } else {
        this.isMobile = false;
        this.apiData.isMobileScreen$.next(this.isMobile);
      }
    });

    this.apiData.isMobileScreen$.subscribe((value) => {
      this.isMobile = value;
      // console.log(value, this.isMobile, "menu size changed");
      this.updateView();
    });

    this.apiData.isMenuChange$.subscribe((value) => {
      this.isMenu = value;
      this.updateView();
    });
    // debugger;
    // console.log("IsMobile Data", this.IsMobile)
    if (this.apiData.subsMenuChange == undefined) {
      this.apiData.subsMenuChange =
        this.apiData.invokeMenuChangeFunction.subscribe((name: string) => {
          this.loadOtherComponent();
        });
    }
    if (this.apiData.subsMenuChange2 == undefined) {
      this.apiData.subsMenuChange2 =
        this.apiData.invokeMenuChangeFunction2.subscribe((name: string) => {
          this.loadMenuComponent();
        });
    }
    this.getMenus();
  }

  toggleMyDiv() {
    const myDiv = document.getElementById("dropdown");
    if (myDiv.style.display === "none") {
      myDiv.style.display = "block";
    } else {
      myDiv.style.display = "none";
    }
  }

  getMenus(i = 0) {
    if (localStorage.getItem(SessionVar.OperatorList)) {
      this.menus = JSON.parse(localStorage.getItem(SessionVar.OperatorList));
      this.displayMenu();
      this.scrollToTop();
    } else {
      this.apiServices.GetOpTypes().subscribe((resp) => {
        if (resp.statuscode == 1) {
          this.scrollToTop();
          console.log(resp);
          localStorage.setItem(
            SessionVar.OperatorList,
            JSON.stringify(resp.data.assignedOpTypes)
          );
          //this.menus = resp.data.assignedOpTypes;
          this.menus = resp.data;
          this.displayMenu();
        }
      });
    }
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  displayMenu() {
    if (this.menus.assignedOpTypes.length > 9) {
      var rem = this.menus.assignedOpTypes.length - 9;
      this.dropmenu = this.menus.assignedOpTypes.slice(
        Math.max(this.menus.assignedOpTypes.length - rem, 1)
      );
    }
    this.isMenuLoaded = true;
    setTimeout(() => {
      this.getRouteID(this.router.url.replace("/", ""));
    }, 100);
  }
  getRouteName(id: any) {
    if (this.isMobile) {
      this.apiData.isMenuChange$.next(true);
      // this.apiData.innerMenu$.next(true);
      // this.updateView()
    } else {
      this.apiData.isMenuChange$.next(false);
      // this.apiData.innerMenu$.next(true);
      // this.updateView()
    }
    localStorage.setItem("isMobile", "false");
    // this.apiData.selectedMenuTab$.next(id)
    this.id = id;
    this.updateView();
    localStorage.setItem("tabId", JSON.stringify(id));

    var routename;
    var caller = "0";

    switch (id) {
      case OpTypes.Prepaid:
        routename = "prepaid.html";
        this.apiData.setHeaderState(OpTypesNames.Prepaid);
        break;
      case OpTypes.PostPaid:
        routename = "postpaid.html";
        this.apiData.setHeaderState(OpTypesNames.PostPaid);
        break;
      case OpTypes.DTH:
        routename = "dth.html";
        this.apiData.setHeaderState(OpTypesNames.DTH);
        break;
      case OpTypes.Landline:
        routename = "landline.html";
        this.apiData.setHeaderState(OpTypesNames.Landline);
        break;
      case OpTypes.Electricity:
        routename = "electricity.html";
        this.apiData.setHeaderState(OpTypesNames.Electricity);
        break;
      case OpTypes.PipedGas:
        routename = "pipedgas.html";
        this.apiData.setHeaderState(OpTypesNames.PipedGas);
        break;
      case OpTypes.Broadband:
        routename = "broadband.html";
        this.apiData.setHeaderState(OpTypesNames.Broadband);
        break;
      case OpTypes.Water:
        routename = "water.html";
        this.apiData.setHeaderState(OpTypesNames.Water);
        break;
      case OpTypes.Gas:
        routename = "gas.html";
        this.apiData.setHeaderState(OpTypesNames.Gas);
        break;
      case OpTypes.Insurance:
        routename = "insurance.html";
        this.apiData.setHeaderState(OpTypesNames.Insurance);
        break;
      case OpTypes.CableTV:
        routename = "cabletv.html";
        this.apiData.setHeaderState(OpTypesNames.CableTV);
        break;
      case OpTypes.Subscription:
        routename = "subscription.html";
        this.apiData.setHeaderState(OpTypesNames.Subscription);
        break;
      case OpTypes.HousingSociety:
        routename = "housingSociety.html";
        this.apiData.setHeaderState(OpTypesNames.HousingSociety);
        break;
      case OpTypes.MunicipalTaxes:
        routename = "municipalTaxes.html";
        this.apiData.setHeaderState(OpTypesNames.MunicipalTaxes);
        break;
      case OpTypes.EducationFees:
        routename = "educationFees.html";
        this.apiData.setHeaderState(OpTypesNames.EducationFees);
        break;
      case OpTypes.Hospital:
        routename = "hospital.html";
        this.apiData.setHeaderState(OpTypesNames.Hospital);
        break;
      case OpTypes.ClubsandAssociations:
        routename = "clubsAndAssociation.html";
        this.apiData.setHeaderState(OpTypesNames.ClubsandAssociations);
        break;
      case OpTypes.BARAssociationFee:
        routename = "barAssociationFee.html";
        this.apiData.setHeaderState(OpTypesNames.BARAssociationFee);
        break;
      case OpTypes.UpiPayment:
        routename = "UpiPayment.html";
        this.apiData.setHeaderState(OpTypesNames.UpiPayment);
        break;
      case OpTypes.FASTag:
        routename = "FASTag.html";
        this.apiData.setHeaderState(OpTypesNames.FASTag);
        break;
      case OpTypes.MoneyTransfer:
        routename = "MoneyTransfer.html";
        this.apiData.setHeaderState(OpTypesNames.MoneyTransfer);
        break;
      case OpTypes.Advertisement:
        routename = "AdvertisementComponent.html";
        this.apiData.setHeaderState(OpTypesNames.Advertisement);
        break;

      default:
        break;
    }
    this.removeClass();
    if (this.getServiceid(id) == "11") {
      routename = "Billpayment/" + routename;
    }
    var element = document.getElementById("menu-" + id);

    if (!element.classList) {
      // document.getElementsByClassName("tab-background")[0].classList.add('auth');
    } else {
      // document.getElementsByClassName("tab-background")[0].classList.remove('auth');
      // element.classList.add("active");

      this.router.navigate(["/" + routename]);
    }
  }

  getRouteID(routename) {
    var id;
    switch (routename) {
      case "prepaid.html":
        id = OpTypes.Prepaid;
        break;
      case "postpaid.html":
        id = OpTypes.PostPaid;
        break;
      case "dth.html":
        id = OpTypes.DTH;
        break;
      case "landline.html":
        id = OpTypes.Landline;
        break;
      case "electricity.html":
        id = OpTypes.Electricity;
        break;
      case "pipedgas.html":
        id = OpTypes.PipedGas;
        break;
      case "broadband.html":
        id = OpTypes.Broadband;
        break;
      case "water.html":
        id = OpTypes.Water;
        break;
      case "gas.html":
        id = OpTypes.Gas;
        break;
      case "insurance.html":
        id = OpTypes.Insurance;
        break;
      case "subscription.html":
        id = OpTypes.Subscription;
        break;
      case "housingSociety.html":
        id = OpTypes.HousingSociety;
        break;
      case "municipalTaxes.html":
        id = OpTypes.MunicipalTaxes;
        break;
      case "educationFees.html":
        id = OpTypes.EducationFees;
        break;
      case "hospital.html":
        id = OpTypes.Hospital;
        break;
      case "clubsAndAssociation.html":
        id = OpTypes.ClubsandAssociations;
        break;
      case "barAssociationFee.html":
        id = OpTypes.BARAssociationFee;
        break;
      case "moneytransfer.html":
        id = OpTypes.MoneyTransfer;
        break;
      case "UpiPayment.html":
        id = OpTypes.UpiPayment;
        break;
      default:
        break;
    }

    var element = document.getElementById("menu-" + id);
    // if (!element) {
    //   document.getElementsByClassName("tab-background")[0].classList.add('auth');
    // }
    // else {
    //   element.classList.add("active");
    // }
  }

  loadOtherComponent() {
    this.removeClass();
    // document.getElementsByClassName("tab-background")[0].classList.add('auth');
  }
  loadMenuComponent() {
    this.removeClass();
    // document.getElementsByClassName("tab-background")[0].classList.remove('auth');
  }
  removeClass() {
    var els = document.querySelectorAll(".cus-nav-item.active");
    for (var i = 0; i < els.length; i++) {
      els[i].classList.remove("active");
    }
  }

  getServiceid(a: number = 0) {
    var b = JSON.parse(localStorage.getItem(SessionVar.OperatorList));
    b = b.assignedOpTypes.filter((x) => x.serviceID == a)[0]["serviceTypeID"];
    return b;
  }
  updateView() {
    if (!this.cdr["destroyed"]) {
      this.cdr.detectChanges();
    }
  }
}
