import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginResp } from 'src/app/enums/apiResponse';
import { ApidataService } from 'src/app/services/apidata.service';
import { ApiService } from 'src/app/services/apiservices.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { LoginReq } from 'src/app/enums/apiRequest';
import { RespCode, SessionVar } from 'src/app/enums/emums';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApisessionService } from 'src/app/services/apisession.service';

@Component({
  selector: 'aditya-forgetpass',
  templateUrl: './forgetpass.component.html',
  styleUrls: ['./forgetpass.component.css']
})
export class ForgetpassComponent implements OnInit {
  LoginForm:FormGroup;
  LoginResp:LoginResp;
  IsLoginSubmitted=false;
  IsLoggingIn=false;
  ErrorMsg='';
  NotificationMsg='';
  FPloader=false;
  isMobile: boolean
  _ngUnsubscribe$ = new Subject<string>();
  constructor(private apiData: ApidataService,
    private apiServices: ApiService,
    private authService: AuthService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private router: Router, private apiSession: ApisessionService,
    private renderer: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    const myDiv = this.el.nativeElement.querySelector('#myDiv');
    this.apiData.isMobileScreen$.subscribe((value) => {
      this.isMobile = value;
      if (value === true) {
        this.renderer.setStyle(myDiv, 'display', 'none');
        this.updateView()
      } else {
        this.renderer.removeStyle(myDiv, 'display');
        this.updateView()
      }
    })
    this.apiData.isMenuChange$.pipe(takeUntil(this._ngUnsubscribe$)).subscribe((value) => {
      if (value === true) {
        this.isMobile = value
        this.renderer.removeStyle(myDiv, 'display');
      }  
    })

    if (localStorage.getItem('isMobile') === 'true') {
      // setTimeout(() => { 
        console.log('isMobile', localStorage.getItem('isMobile'));
        this.renderer.setStyle(myDiv, 'display', 'none');
      // },50)
    } else {
      this.renderer.removeStyle(myDiv, 'display');
    }
    
    this.apiData.loadOtherClass();
    this.LoginForm=this.fb.group({
      UserID:this.fb.control('',[Validators.required, Validators.maxLength(10), Validators.minLength(10),Validators.pattern('\\d{10}')])
      
    })
  }
  get l(){ return this.LoginForm.controls}

  gotoSignUp()
  {
    this.apiData.gotoPage('signup.html');
    this.apiData.setHeaderState('Sign Up');
    localStorage.setItem('isMobile', 'false')
    // this.apiData.isMenuChange$.next(true);
  }
  gotoLogin()
  {
    this.apiData.gotoPage('login.html');
    this.apiData.setHeaderState('Login');
    localStorage.setItem('isMobile', 'false')
    // this.apiData.isMenuChange$.next(true);
  }

  forgetPassword()
  {
    this.IsLoginSubmitted=true;

    var req:any={
      
      userID:this.LoginForm.get('UserID').value
    }
   
    if(this.LoginForm.status=='INVALID')
    {
      //this.IsLoginSubmitted=false;
      return;
    }
    if(this.IsLoginSubmitted)
    {
      this.IsLoggingIn=true;
      this.FPloader=true;
      this.apiData.LoaderToggle();
      this.apiServices.ForgetPassword(req).subscribe((resp:any)=>{
        this.apiData.LoaderToggle();
        this.FPloader=false;
        if(resp.statuscode==RespCode.Success)
        {
          this.IsLoggingIn=false;
          this.NotificationMsg=resp.msg+" and click to login";
        }
      })
      //this.IsLoggingIn=false;
      // this.ErrorMsg='This Service is not working';
    }
  }
  updateView() {
    if (!this.cdr['destroyed']) {
    this.cdr.detectChanges();
  }
}
}
