import { Directive, ElementRef, OnDestroy, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApidataService } from './services/apidata.service';

@Directive({
  selector: '[adityaAppMobileForm]'
})
export class AppMobileFormDirective implements OnDestroy {
  private _ngUnsubscribe$ = new Subject<void>();

  constructor(private el: ElementRef, private renderer: Renderer2 ,private apiData: ApidataService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    const myDiv = this.el.nativeElement;

    combineLatest([
      this.apiData.isMobileScreen$,
      this.apiData.isMenuChange$.pipe(takeUntil(this._ngUnsubscribe$))
    ]).subscribe(([isMobile, isMenuChange]) => {
      if (isMobile) {
        this.renderer.addClass(myDiv, 'mobile-form');
      } else {
        this.renderer.removeClass(myDiv, 'mobile-form');
      }

      this.updateView();
    });
  }

  updateView() {
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
