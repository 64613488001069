import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberToWords {
  othersArr :string[];
  divisor:any;
  func:any;
  constructor() { }
  form:any;
  
   units = ["Zero", "One", "Two", "Three", "Four", "Five", "Six",
  "Seven", "Eight", "Nine", "Ten"];
   teens = ["Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen",
  "Sixteen", "Seventeen", "Eighteen", "Nineteen", "Twenty"];
  tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty",
  "Seventy", "Eighty", "Ninety"];

 othersIndian = ["Thousand", "Lakh", "Crore"];

 othersIntl = ["Thousand", "Million", "Billion", "Trillion"];

 INDIAN_MODE = "indian";
 INTERNATIONAL_MODE = "international";
 currentMode = this.INDIAN_MODE;

 getBelowHundred = function (n) {
  if (n >= 100) {
      return "greater than or equal to 100";
  }
  if (n <= 10) {
      return this.units[n];
  }
  if (n <= 20) {
      return this.teens[n - 10 - 1];
  }
  var unit = Math.floor(n % 10);
  n /= 10;
  var ten = Math.floor(n % 10);
  var tenWord = ten > 0 ? this.tens[ten] + " " : '';
  var unitWord = unit > 0 ? this.units[unit] : '';
  return tenWord + unitWord;
};

 getBelowThousand = function (n) {
  if (n >= 1000) {
      return "greater than or equal to 1000";
  }
  var word = this.getBelowHundred(Math.floor(n % 100));

  n = Math.floor(n / 100);
  var hun = Math.floor(n % 10);
  word = (hun > 0 ? this.units[hun] + " Hundred " : '') + word;

  return word;
};


numberToWords = (n) => {
    if (isNaN(n)) {
        return "Not a number";
    }

    var word = '';
    var val;

    val = Math.floor(n % 1000);
    n = Math.floor(n / 1000);

    word = this.getBelowThousand(val);

    if (this.currentMode === this.INDIAN_MODE) {
        this.othersArr = this.othersIndian;
        this.divisor = 100;
        this.func = this.getBelowHundred;
    }
    else if (this.currentMode === this.INTERNATIONAL_MODE) {
        this.othersArr = this.othersIntl;
        this.divisor = 1000;
        this.func = this.getBelowThousand;
    }
    else {
        throw "Invalid mode - '" + this.currentMode
        + "'. Supported modes: " + this.INDIAN_MODE + "|"
        + this.INTERNATIONAL_MODE;
    }

    var i = 0;
    while (n > 0) {
        if (i === this.othersArr.length - 1) {
            word = this.numberToWords(n) + " " + this.othersArr[i] + " "
                + word;
            break;
        };
        val = Math.floor(n % this.divisor);
        n = Math.floor(n / this.divisor);
        if (val !== 0) {
            word = this.func(val) + " " + this.othersArr[i] + " " + word;
        }
        i++;
    }
    return word;
}
setMode = (mode)=> {
    if (mode !== this.INDIAN_MODE && mode !== this.INTERNATIONAL_MODE) {
        throw "Invalid mode specified - '" + mode
        + "'. Supported modes: " + this.INDIAN_MODE + "|"
        + this.INTERNATIONAL_MODE;
    }
    this.currentMode = mode;
}

toWords = (_input) => {
    
    if (isNaN(_input)) {
        return "";
    }
    this.setMode("indian");
    var num = _input.toString().split('.');
    var kk = this.numberToWords(num[0]);
    let _val2:any = num[1];
    let str = '';
    if (_val2 != undefined) {
        var units = ["Zero", "One", "Two", "Three", "Four", "Five", "Six","Seven", "Eight", "Nine", "Ten"];
        str += ' point ';
        for (var i = 0; i < _val2.length; i++) {
            var unit = Math.floor(_val2[i] % 10);
            str += units[unit] + ' ';
        }
    }
    return kk + str;
}

}
