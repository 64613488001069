import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aditya-pagecontainer',
  templateUrl: './pagecontainer.component.html',
  styleUrls: ['./pagecontainer.component.css']
})
export class PagecontainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
