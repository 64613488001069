import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'adtiya-bottomheader',
  templateUrl: './bottomheader.component.html',
  styleUrls: ['./bottomheader.component.css']
})
export class BottomheaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
