import { Component,AfterContentInit, OnDestroy, OnInit, TemplateRef,Pipe, PipeTransform, ViewChild  } from '@angular/core';
import { Select2OptionData,Select2TemplateFunction } from 'ng2-select2';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ApidataService } from 'src/app/services/apidata.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { BsModalRef, BsModalService  } from 'ngx-bootstrap/modal';
import { OpTypes, APIUrl,RespCode} from 'src/app/enums/emums';
import { ApiService } from 'src/app/services/apiservices.service';
import { ApisessionService } from 'src/app/services/apisession.service';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { NumberToWords } from 'src/app/services/NumberToWords.service';
import { Print } from 'src/app/services/Print.service'
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RechargeReportResp } from 'src/app/enums/apiResponse';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { SenderDetailReq,AddBeneRequest,VerifyBeneRequest,RechargeReportReq,AppSendMoneyReq,AppAmountRequest,AppDMTRecieptReq } from 'src/app/enums/apiRequest';
@Component({
  selector: 'aditya-moneytransfer',
  templateUrl: './moneytransfer.component.html',
  styleUrls: ['./moneytransfer.component.css']
})
export class MoneytransferComponent implements OnInit {

  private total=0;    
  private value; 
  amount: number;
  isInProgress:boolean=false;
  IsSenderSubmitted=false;
  IsReportLoaded=false;
  IsShowSenderForm=false;
  IsShowSenderDetails=false;
  IsVrifySenderOtp=false;
  IsLoadershow = false;
  ISverifyCall= false;
  Domain='';
  spnMobile='';
  spnAmount='';
  spnError='';
  spnSuccess='';
  AccountRemark=''
  spnFirstName='';
  DmtTranReceipt: any;
  AllTotalInWods: any;
  SearchData:any;
  mobile: any;
  odata:any;
  bank:any;
  SendMoneyBeneDetails:any;
  RechargeForm: FormGroup;
  SenderForm: FormGroup;
  BeneficiaryForm: FormGroup;
  firstname:number;
  MobileplaceHolder='Select Electricity Operator'
  colorTheme = 'theme-dark-blue';
  public operator=0;
  public OperatorData: Array<Select2OptionData>;
  public filteredOperator: Observable<Array<Select2OptionData>>;
  public OperatorOptions: Select2Options;
  DOBConfig: Partial<BsDatepickerConfig>;
  SearchResp:RechargeReportResp;
// SendMoney Poppu
AddBeneficiaryModalView: BsModalRef;
SendMoneyView: BsModalRef;
SuccessFailPopupView:BsModalRef;
bankname:string=''
accountno:string=''
benename:string=''
TranAmount:number
TranMode:string='NEFT'
Charges:number
Total:number
IsChargedAmount:boolean=false
// SendMoney Poppu End

  slides = [];
  slideConfig = { "slidesToShow": 1, "slidesToScroll": 1, autoplay: true, autoplaySpeed: 2000, arrows: true };
  beneficiaries=[];
  public bankmaster=[];

SenderName='';
RemainingLimit='0';
TotalLimit='0';
setifsc='';
statuscode:number=0;
msg:string='';
IsbannerShow = true;
DOBDate=new Date();
config = {
  keyboard: false,
  ignoreBackdropClick: true
};


  constructor(private apiData:ApidataService,private router:Router,private authService:AuthService,
    private fb: FormBuilder, private apiService: ApiService,private apisession: ApisessionService,
     private FormValidation: FormValidationService,private num2word: NumberToWords,private print:Print,
      protected _sanitizer: DomSanitizer,private modalService: BsModalService) { 
        this.DOBConfig = Object.assign({}, {maxDate: this.DOBDate, containerClass: this.colorTheme,
          dateInputFormat: 'DD MMM YYYY',adaptivePosition: true });
          
      }
     
  

  ngOnInit() {
    this.Domain=APIUrl.Domain;
    this.DOBDate.setDate(this.DOBDate.getDate());
    this.TransferReport();
    this.OperatorOptions= {
      multiple: false,
      closeOnSelect: true,
      
      templateResult: this.templateResult,
      templateSelection: this.templateSelection
    };
         
    this.SenderForm=this.fb.group({
      firstname:this.fb.control('',[Validators.required]),
      lastname:this.fb.control('', [Validators.required]),
      dob:this.fb.control('', [Validators.required]),
      pincode:this.fb.control('', [Validators.required]),
      address:this.fb.control('', [Validators.required]),
      otp:this.fb.control('', [Validators.required])
    })
    this.RechargeForm=this.fb.group({
      mobile:this.fb.control('',[Validators.required]),
      amount: this.fb.control('', [Validators.required]),
       myControl: this.fb.control('')
    })

    
   
    this.BeneficiaryForm=this.fb.group({
      ID:this.fb.control(''),
      Bank:this.fb.control(''),
      IFSCCode:this.fb.control(''),
      Account:this.fb.control(''),
      Beneficiary:this.fb.control(''),
      BenMobile:this.fb.control(''),
      TranMode:this.fb.control(''),
      myControl: this.fb.control('')
    })


    this.OperatorData=this.apiData.getOperator(this.apiData.getRouteID(this.router.url.replace('/','').replace('.html','')));
   
    this.GetB2CBanner();
    this.filteredOperator = this.RechargeForm.controls['myControl'].valueChanges
    .pipe(
      startWith(''),
      map(value => this._filterOperator(value))
    );
  
  }
  private _filterOperator(object: any): Array<Select2OptionData> {
   
    let value = typeof (object) === 'object' ? object.text : object;
    if (value != null && value != "") {
      var filterValue = value.toLowerCase();
      var data = this.OperatorData.filter(operator => operator.text.toLowerCase().includes(filterValue))
      return data;
    }
    else
      return this.OperatorData;
  }
  get r(){ return this.RechargeForm.controls}
  get r1(){ return this.SenderForm.controls}
  
  Operatorchangednew(event: any): void {

    this.operator = parseInt(event.option.value.id);
    if (this.operator == 0) {
      this.MobileplaceHolder = 'Select Electricity Operator';
      return;
    }
    this.odata = this.apiData.getOperatorData(this.operator);
    
    this.MobileplaceHolder = this.odata.accountName;
    this.AccountRemark = this.odata.accountRemak;
    if (this.odata.isAccountNumeric)
      this.RechargeForm.controls['mobile'].setValidators([Validators.minLength(this.odata.length), Validators.maxLength(this.odata.lengthMax), Validators.pattern('^[0-9]+$')]);
    else
      this.RechargeForm.controls['mobile'].setValidators([Validators.minLength(this.odata.length), Validators.maxLength(this.odata.lengthMax)]);
    this.RechargeForm.controls['amount'].setValidators([Validators.min(this.odata.min), Validators.max(this.odata.max), Validators.pattern('^[0-9]+(\.?[0-9]?)')]);

  }
  public templateResult: Select2TemplateFunction = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
  
    let image = '<span class="dropdown-img"></span>';
  
    if (state.additional.image) {
      image = '<span class="dropdown-img" ><img  src="' + state.additional.image + '"</span>';
    }
  
    return jQuery('<span></b> ' + image + ' <span>' + state.text + '</span></span>');
  }


  onChange(event: any) {
    
    this.BeneficiaryForm.controls['IFSCCode'].setValue(event);
    let b=this.bankmaster.filter(function(x){
      if(x.ifsc==event){
        return x;
      }
    });
    this.bank=b[0];
  }
  
  // function for selection template
  public templateSelection: Select2TemplateFunction = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
  
    // return jQuery('<span> ' + state.text + '</span>');
    let image = '<span class="dropdown-img"></span>';
  
    if (state.additional.image) {
      image = '<span class="dropdown-img" ><img  src="' + state.additional.image + '"</span>';
    }
    
    var sub=state.text.length>35 ?state.text.substring(0,35)+'...':state.text;
    
    return jQuery('<span class="search-ddl"></b> ' + image + ' <span>' + sub + '</span></span>');
  }
  GetB2CBanner()
  {
    var req={opType:OpTypes.MoneyTransfer};
    this.apiService.GetB2CBanner(req).subscribe(resp=>{
     
    if(resp.bannerUrl)
    {
      this.slides=resp.bannerUrl;
      
    }
    })
  }
  public displayFn(data?: Select2OptionData): string {
    return data ? data.text : '';
  }
  transform(value: string, type?: string): SafeHtml | SafeUrl | SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustUrl(value);

  }
  getFormValues(val: string): string {
    return this.SenderForm.controls[val].value;
  }
  getbankFormValues(val: string): string {
    return this.BeneficiaryForm.controls[val].value;
  }
  createsender() {
   this.IsSenderSubmitted=true;
    this.spnError='';
    this.spnSuccess='';

    if (this.SenderForm.invalid) {
      return;
      }
      if(this.mobile=='')
      {
          return;
      }
      if(this.getFormValues('firstname')=='')
      {
        this.spnFirstName = "First Name  is required";
        this.spnError="First Name Required";
          return;
      }
      if(this.getFormValues('lastname')=='')
      {
        this.spnError="Last Name Required";
          return;
      }
      if(this.getFormValues('dob')=='')
      {
        this.spnError="DOB Name Required";
          return;
      }
      if(this.getFormValues('pincode')=='')
      {
        this.spnError="Pincode Name Required";
        return;
      }
      if(this.getFormValues('address')=='')
      {
        this.spnError="Address Required";
          return;
      }
  if(this.operator==0)
    {
      this.spnError="Invalid opreator";
     return;
    }
    this.IsLoadershow=true;
    this.apiData.LoaderToggle();
      let obj:SenderDetailReq = {senderRequest:{MobileNo: this.mobile,Name:this.getFormValues('firstname'),
        LastName:this.getFormValues('lastname'),Dob:this.getFormValues('dob'),Address:this.getFormValues('address'),Pincode:this.getFormValues('pincode')},OID:this.operator} as SenderDetailReq;
        this.apisession.CreateSender(obj).subscribe(resp=>{
          this.IsLoadershow=false;
          this.apiData.LoaderToggle();
          if(resp.statuscode==1)
          {
            if(resp.isOTPRequired==true)
            {
              this.spnSuccess="Otp Reuired Enter Otp";
              this.IsVrifySenderOtp=true;
            }
            else{
              this.GetSenderDetails();
            }
          }
          else
          {
            this.spnError=resp.msg;
            return;
          }
      })
  }
  VerifySender() {
    
    this.IsLoadershow=true;
    this.apiData.LoaderToggle();
    this.spnError='';
    this.spnSuccess='';
    if(this.getFormValues('otp')=='')
    {
      this.spnError="Enter Otp.!";
      return;
    }
    if(this.operator==0)
    {
      this.spnError="Invalid opreator";
     return;
    }
    let obj:SenderDetailReq = {senderRequest:{MobileNo: this.mobile,OTP:this.getFormValues('otp')},OID:this.operator} as SenderDetailReq;
     this.apisession.VerifySender(obj).subscribe(resp=>{
      this.IsLoadershow=false;
      this.apiData.LoaderToggle();
        if(resp.statuscode==1)
        {
         
          this.GetSenderDetails();
        }
        else
        {
        
          this.spnError=resp.msg;
          return;
        }
    })
}
  


  GetSenderDetails() {
    this.IsLoadershow=true;
    this.apiData.LoaderToggle();
    if (this.mobile && this.mobile.length==10) {
      let obj:SenderDetailReq = {senderRequest:{MobileNo: this.mobile},OID:this.operator} as SenderDetailReq;
    
      this.apisession.GetSenderDetails(obj).subscribe(resp=>{
        this.IsLoadershow=false;
        this.apiData.LoaderToggle();
        if(resp.statuscode==1 )
        {
          this.IsbannerShow=false;
          if(resp.isSenderNotExists==true)
          {
            this.IsShowSenderDetails=false;
            this.IsShowSenderForm=true;
          }
          else{
            this.IsShowSenderDetails=true;
            this.IsShowSenderForm=false;
            this.SenderName=resp.senderName;
            this.RemainingLimit=resp.remainingLimit;
            this.TotalLimit=resp.availbleLimit;
            this.GetBeneficiary();
          }
        }
        else
        {
          //Error Message Display Here
        }
      })
    }
  }
  
  GetBeneficiary() {
    if (this.mobile && this.mobile.length==10) {
      let obj:SenderDetailReq = {senderRequest:{MobileNo: this.mobile},OID:this.operator} as SenderDetailReq;
      this.apisession.GetBeneficiary(obj).subscribe(resp=>{
        if(resp.statuscode==1 )
        {
          this.beneficiaries=resp.beneficiaries;
        }
      })
    }
  }

  onClickOpenBenForm(template: TemplateRef<any>){
    this.statuscode=0;
    this.msg='';
    this.BeneficiaryForm.controls['BenMobile'].setValue(this.mobile);
    this.apisession.GetBank().subscribe(resp=>{
   if(resp.statuscode==1)
       this.bankmaster=resp.bankMasters;
       
    })
    this.AddBeneficiaryModalView = this.modalService.show(template,this.config);
    this.AddBeneficiaryModalView.setClass('modal-md');
  }
  SendMoneyPopup(template: TemplateRef<any>,bene:any){
    if(bene!=null)
    {
      this.bankname=bene.bankName;
      this.accountno=bene.accountNo;
      this.benename=bene.beneName;
      this.SendMoneyBeneDetails=bene;
    }

    this.SendMoneyView = this.modalService.show(template,this.config);
    this.SendMoneyView.setClass('modal-md');
  }


  CreateBeneficiary() {
    this.spnError='';
    this.spnSuccess='';
      if(this.mobile=='')
      {
          return;
      }
      if(this.getbankFormValues('IFSCCode')=='')
      {
        this.spnError="IFSC Reuired";
          return;
      }
      if(this.getbankFormValues('BenMobile')=='')
      {
        this.spnError="BenMobile Reuired";
          return;
      }
      if(this.getbankFormValues('Account')=='')
      {
        this.spnError="Account Reuired";
          return;
      }
      if(this.getbankFormValues('Beneficiary')=='')
      {
        this.spnError="Beneficiary Reuired";
          return;
      }
    let bankreq:AddBeneRequest = {senderRequest:{MobileNo: this.mobile},
    beneDetail:{BankID:this.bank.id,BankName:this.bank.bankName,
    AccountNo:this.getbankFormValues('Account'),
    BeneName:this.getbankFormValues('Beneficiary'),
    IFSC:this.getbankFormValues('IFSCCode'),
    MobileNo:this.getbankFormValues('BenMobile'),
    TransMode:this.getbankFormValues('TranMode')=='NEFT'?0:1},OID:this.operator} as AddBeneRequest;
    this.apisession.AddBeneficiary(bankreq).subscribe(resp=>{
        if(resp.statuscode==1)
        {
          this.GetSenderDetails();
        }
        else
        {
          this.spnError=resp.msg;
        }
    })
  
}

VerifyAccountP() {
  this.ISverifyCall=true;
  this.spnError='';
  this.spnSuccess='';
    if(this.mobile=='')
    {
        return;
    }
    if(this.getbankFormValues('IFSCCode')=='')
    {
      this.ISverifyCall=false;
      this.spnError="IFSC Reuired";
        return;
    }
    if(this.getbankFormValues('BenMobile')=='')
    {
      this.ISverifyCall=false;
      this.spnError="BenMobile Reuired";
        return;
    }
    if(this.getbankFormValues('Account')=='')
    {
      this.ISverifyCall=false;
      this.spnError="Account Reuired";
        return;
    }
   
    let bankreq:VerifyBeneRequest = {senderRequest:{MobileNo: this.mobile},
    beneDetail:{BankID:this.bank.id,BankName:this.bank.bankName,
    AccountNo:this.getbankFormValues('Account'),
    BeneName:this.getbankFormValues('Beneficiary'),
    IFSC:this.getbankFormValues('IFSCCode'),
    MobileNo:this.getbankFormValues('BenMobile'),
    TransMode:this.getbankFormValues('TranMode')=='NEFT'?0:1},OID:this.operator} as AddBeneRequest;
    console.log(bankreq);
    this.apisession.VerifyAccountP(bankreq).subscribe(resp=>{
    console.log(resp);
    
    if(resp.statuscode==2)
    {
      this.ISverifyCall=false;
      this.BeneficiaryForm.controls['Beneficiary'].setValue(resp.beneName);
      //this.GetSenderDetails();
    }
    else
    {
      this.ISverifyCall=false;
      this.BeneficiaryForm.controls['Beneficiary'].setValue('');
      this.spnError=resp.msg;
    }
})

  
  
}
DeleteBeneP(beneID:string) {
  if(beneID=='0' || beneID=='')
  {
    alert('Invalid Request')
    return;
  }
  let bankreq:AddBeneRequest = {senderRequest:{MobileNo: this.mobile},
  beneDetail:{BeneID:beneID},OID:this.operator} as AddBeneRequest;
  this.apisession.DeleteBeneficiary(bankreq).subscribe(resp=>{
      if(resp.statuscode==1)
      {
        this.GetSenderDetails();
      }
      else
      {
        alert(resp.msg);
      }
  })

}



GetChargedAmount() {
  this.spnAmount='';
  this.spnError="";
if(this.TranAmount==null || this.TranAmount==0)
{
  this.spnAmount="Amount Required."
return;
}

  let appamountreq:AppAmountRequest = {Amount:this.TranAmount,OID:this.operator} as AppAmountRequest;
  this.apisession.GetChargedAmount(appamountreq).subscribe(resp=>{
   
    if(resp.statuscode==1)
    {
     this.Charges=resp.chargedAmount;
     this.Total=this.TranAmount+Number(resp.chargedAmount);
     this.IsChargedAmount=true;
    }
    else{
      this.spnAmount=resp.msg;
      return;
    }
})
}

MoneyTransfer(template: TemplateRef<any>) {
  this.spnError="";
  
  this.isInProgress=true;
  let moneyTransferReq:AppSendMoneyReq = {reqSendMoney:{
       BeneID:this.SendMoneyBeneDetails.beneID,
      MobileNo: this.mobile,
      IFSC:this.SendMoneyBeneDetails.ifsc,
      AccountNo:this.SendMoneyBeneDetails.accountNo,
      Amount:Number(this.TranAmount),
      Bank:this.SendMoneyBeneDetails.bankName,
      BeneName :this.SendMoneyBeneDetails.beneName,
       BeneMobile:this.SendMoneyBeneDetails.mobileNo,
       BankID:this.SendMoneyBeneDetails.bankID,
       o:this.operator,
      Channel:this.TranMode=='NEFT'?false:true
  }} as AppSendMoneyReq;
  this.apisession.MoneyTransfer(moneyTransferReq).subscribe(resp=>{
    if(resp.statuscode==1 || resp.statuscode==2)
    {
      this.isInProgress=false;
      this.SuccessFailPopupShow(template,resp.groupID);
    }
   else
    {
      this.isInProgress=false;
      this.spnError=resp.msg;
    }
})
}
test(template: TemplateRef<any>){
  // SendMoneyPopup(template: TemplateRef<any>,bene:any){
  this.SuccessFailPopupShow(template,'S22052715334150DA240');
}
SuccessFailPopupShow(template: TemplateRef<any>,groupID:string){
  let dMTRecieptReq:AppDMTRecieptReq = {GroupID:groupID} as AppDMTRecieptReq;
  this.apisession.DMTReceipt(dMTRecieptReq).subscribe(resp=>{
 
    if(resp.statuscode==1)
    {
      this.DmtTranReceipt=resp.transactionDetail;
      this.findsum(resp.transactionDetail.lists);
    }
})
  this.SuccessFailPopupView = this.modalService.show(template,this.config);
  this.SuccessFailPopupView.setClass('modal-lg');
}
tranmodechange(tranmodechange:string){
 this.TranMode=tranmodechange;
}
  inputclear() {
    this.RechargeForm.controls['myControl'].setValue(' ');
  }
  public closeSendModal() { 
    this.TranAmount=0;
    this.IsChargedAmount=false;
    this.SendMoneyView.hide();
    this.SendMoneyView = null;
  }
  public closePrintModal() { 
    this.closeSendModal();
    this.SuccessFailPopupView.hide();
    this.SuccessFailPopupView = null;
  }


  findsum(data){ 
   this.value=data  
   for(let j=0;j<data.length;j++){   
   this.total+= this.value[j].requestedAmount
    }  
      this.AllTotalInWods=this.num2word.toWords(this.total.toString());
  } 
  printmodal()
  {
    // printData("Id Of Printind div","External csslink ");
    this.print.printData("printDiv","");
  }
  onReset() {
    this.IsSenderSubmitted = false;
    this.SenderForm.reset();
}


TransferReport()
  {
    var req:RechargeReportReq=
    {
      isRecent:true,
      fromDate:"",
      toDate:"",
      accountNo:'',
      apiid:0,
      criteriaID:0,
      transactionID:'',
      isExport:false,
      oid:0,
      opTypeID:14,
      status:0,
      topRows:10
    }
    this.IsReportLoaded=false;
    this.apisession.GetTopfive(req).subscribe(resp=>{
      this.SearchResp=resp;
      console.log(this.SearchResp);
      if(this.SearchResp.statuscode==RespCode.Success)
      {
        this.SearchData=this.SearchResp.rechargeReport;
        this.IsReportLoaded=true;
      }
      else
      {
        this.IsReportLoaded=false;
      }      
    })
  }

}
