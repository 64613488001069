import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ApidataService } from "src/app/services/apidata.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../../../services/apiservices.service";
import { AuthService } from "../../../services/auth.service";
import { LoginReq } from "../../../enums/apiRequest";
import { LoginResp } from "../../../enums/apiResponse";
import { OpTypesNames, RespCode, SessionVar } from "../../../enums/emums";
import { Router } from "@angular/router";
import { ApisessionService } from "src/app/services/apisession.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ClassField } from "@angular/compiler";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "aditya-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  LoginForm: FormGroup;
  LoginResp: LoginResp;
  IsLoginSubmitted = false;
  IsLoggingIn = false;
  @ViewChild("forgot-password") forgotPassword: any;
  modalRef: BsModalRef;
  ErrorMsg = "";
  isMobile: boolean;
  tab: string = "email";
  mobile = "";
  otp: any;
  otpEnabled: boolean = false;
  otpSession = "";
  _ngUnsubscribe$ = new Subject<string>();
  constructor(
    private apiData: ApidataService,
    private apiServices: ApiService,
    private authService: AuthService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private router: Router,
    private apiSession: ApisessionService,
    private renderer: Renderer2,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    const myDiv = this.el.nativeElement.querySelector("#myDiv");
    this.apiData.isMobileScreen$.subscribe((value) => {
      this.isMobile = value;
      if (value === true) {
        // this.renderer.setStyle(myDiv, 'display', 'none');
        // this.renderer.addClass(myDiv, 'mobile-form');
        myDiv.classList.add("mobile-form");
        console.log("if condition is true", this.isMobile);
        this.updateView();
      } else {
        // this.renderer.removeStyle(myDiv, 'display');
        // this.renderer.removeClass(myDiv, 'mobile-form');
        myDiv.classList.remove("mobile-form");
        console.log("else condition is true", this.isMobile);
        this.updateView();
      }
    });
    this.apiData.isMenuChange$
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe((value) => {
        if (value === true) {
          this.isMobile = value;
          // this.renderer.removeStyle(myDiv, 'display');
          // this.renderer.removeClass(myDiv, 'mobile-form');
          console.log("if condition is menu", this.isMobile);
          myDiv.classList.remove("mobile-form");
        }
      });
    if (localStorage.getItem("isMobile") === "true") {
      // this.renderer.setStyle(myDiv, 'display', 'none');
      // this.renderer.addClass(myDiv, 'mobile-form');
      myDiv.classList.add("mobile-form");
      console.log("if condition is true", this.isMobile);
    } else {
      // this.renderer.removeStyle(myDiv, 'display');
      // this.renderer.removeClass(myDiv, 'mobile-form');
      myDiv.classList.remove("mobile-form");
      console.log("else condition is true", this.isMobile);
    }
    this.apiData.loadOtherClass();
    this.LoginForm = this.fb.group({
      UserID: this.fb.control("", [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
        Validators.pattern("\\d{10}"),
      ]),
      Password: this.fb.control("", [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(12),
      ]),
    });
  }
  get l() {
    return this.LoginForm.controls;
  }

  openForgotModal() {
    console.log("openForgotModal");
    this.modalService.show(this.forgotPassword);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalService.hide(this.forgotPassword);
  }

  openForm(val: string) {
    this.tab = val;
    this.cdr.detectChanges();
  }

  onGetOtp() {
    if (!this.mobile) {
      this.toastr.error("Please enter mobile number", "Error");
      return;
    }
    const indianNumberRegex = /^[6789]\d{9}$/;
    if (!indianNumberRegex.test(this.mobile)) {
      this.toastr.error("Please enter valid mobile number", "Error");
      return;
    }
    let req = {
      userID: this.mobile,
    };
    this.spinner.show();
    this.apiServices.getOtp(req).subscribe((resp: any) => {
      if (resp.statuscode === 2) {
        this.toastr.success("Otp sent successfully.", "Success");
        this.otpEnabled = true;
        this.otpSession = resp.otpSession;
        this.cdr.detectChanges();
        console.log("first", resp);
      } else {
        this.otpEnabled = false;
        this.toastr.error(resp.msg, "Erorr");
      }
      this.spinner.hide();
    });
  }

  onVerifyOtp() {
    this.IsLoginSubmitted = true;
    let body: any = {
      oTPSession: this.otpSession,
      otp: this.otp,
      oTPType: 1,
    };
    console.log(" verification", body);
    if (this.IsLoginSubmitted) {
      this.apiServices.VarifyOTP(body).subscribe((resp) => {
        if (resp.statuscode === -1) {
          this.otpEnabled = true;
          this.toastr.error(resp.msg, "Error");
          return;
        }
        this.LoginResp = resp;
        if (this.LoginResp.statuscode == RespCode.Success) {
          this.toastr.success("Login successfully", "Success");
          this.IsLoggingIn = true;
          localStorage.setItem("isMobile", "false");
          this.apiData.setHeaderState(OpTypesNames.Prepaid);
          // this.apiData.isMenuChange$.next(true);
          this.authService.authProcess(this.LoginResp);
          this.apiData.setSessionData(SessionVar.LoginInfo, this.LoginResp);
          this.apiData.login();
          if (this.apiData.getSessionData(SessionVar.TransactionRequest)) {
            this.apiData.loadOtherClass();
            this.router.navigate(["redirecttoaction.html"], {
              queryParams: {
                reff: "3309a24d426f5ee0d77b91f885ee641b",
                aid: "538536ff5636f4dc4e894b16182a3165b8413ac0cbabf91126fe2b8be4795f86d3a59a416a6b7b8920d00b0af0109b50",
              },
            });
          } else {
            this.apiData.gotoMenu("prepaid.html");
          }
          // } else if (this.LoginResp.statuscode == RespCode.otp) {
          //   this.apiData.setSessionData(SessionVar.OTP, this.LoginResp);

          //   this.apiData.gotoPage("otp.html");
          // }
          //    else {
          //     this.ErrorMsg = this.LoginResp.msg;
          //   }
          this.IsLoggingIn = false;
        }
      });
    }
  }

  login() {
    this.IsLoginSubmitted = true;
    let body = {
      mobile: this.mobile,
      otp: this.otp,
    };
    var req: any =
      this.tab === "email"
        ? {
            password: this.LoginForm.get("Password").value,
            userID: this.LoginForm.get("UserID").value,
          }
        : body;
    console.log("login Request: " + req);
    if (this.LoginForm.status == "INVALID") {
      return;
    }
    if (this.IsLoginSubmitted) {
      this.apiServices.Login(req).subscribe((resp) => {
        this.LoginResp = resp;
        if (this.LoginResp.statuscode == RespCode.Success) {
          this.toastr.success("Login successfully", "Success");
          this.IsLoggingIn = true;
          localStorage.setItem("isMobile", "false");
          this.apiData.setHeaderState(OpTypesNames.Prepaid);
          // this.apiData.isMenuChange$.next(true);
          this.authService.authProcess(this.LoginResp);
          this.apiData.setSessionData(SessionVar.LoginInfo, this.LoginResp);
          this.apiData.login();
          if (this.apiData.getSessionData(SessionVar.TransactionRequest)) {
            this.apiData.loadOtherClass();
            this.router.navigate(["redirecttoaction.html"], {
              queryParams: {
                reff: "3309a24d426f5ee0d77b91f885ee641b",
                aid: "538536ff5636f4dc4e894b16182a3165b8413ac0cbabf91126fe2b8be4795f86d3a59a416a6b7b8920d00b0af0109b50",
              },
            });
          } else {
            this.apiData.gotoMenu("prepaid.html");
          }
        } else if (this.LoginResp.statuscode == RespCode.otp) {
          this.apiData.setSessionData(SessionVar.OTP, this.LoginResp);

          this.apiData.gotoPage("otp.html");
        } else {
          this.ErrorMsg = this.LoginResp.msg;
        }
        this.IsLoggingIn = false;
      });
    }
  }
  gotoSignUp() {
    this.apiData.gotoPage("signup.html");
    this.apiData.setHeaderState("Sign Up");
    localStorage.setItem("isMobile", "false");
    // this.apiData.isMenuChange$.next(true);
  }
  gotoForgetPass() {
    this.apiData.gotoPage("forgetpass.html");
    this.apiData.setHeaderState("Forgot Password");
    localStorage.setItem("isMobile", "false");
    // this.apiData.isMenuChange$.next(true);
  }
  SubmitFacebookLogin() {
    var a = 0;
  }
  updateView() {
    if (!this.cdr["destroyed"]) {
      this.cdr.detectChanges();
    }
  }

  openVirtualKeyboard(id: string) {
    // alert('Virtual keyboard' + this.isMobile )
    const inputField = this.el.nativeElement.querySelector(`#${id}`);
    inputField.focus();
  }

  onInputFieldClick(event: Event) {
    console.log("event prpagation", event);
    event.stopPropagation();
  }
}
