import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aditya-failed-page',
  templateUrl: './failed-page.component.html',
  styleUrls: ['./failed-page.component.css']
})
export class FailedPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
