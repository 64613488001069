import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aditya-refundAndCancellation',
  templateUrl: './refundAndCancellation.component.html',
  styleUrls: ['./refundAndCancellation.component.css']
})
export class RefundAndCancellationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
