import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { HeaderInfo, ThemeType } from './enums/emums';
import { Title } from '@angular/platform-browser';
import { ApidataService } from './services/apidata.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = HeaderInfo.Title;
  public static themeType = ThemeType.Old;
  isMobile: any

  constructor(private titleService: Title, private cdr: ChangeDetectorRef, private apiData: ApidataService) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.detectDevice();
  }
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   // this.isMobile = event.innerwidth < 768
  //   // this.apiData.isMobileScreen$.next(this.isMobile);
  //   // localStorage.setItem('isMobile', this.isMobile)
  //   this.detectDevice();
  // }
  detectDevice() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
    this.apiData.isMobileScreen$.next(this.isMobile);
    localStorage.setItem('isMobile', this.isMobile)
    console.log(window.navigator.userAgent, this.isMobile);
    // this.updateView();
  }
  updateView() {
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }
}
