import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aditya-datacard',
  templateUrl: './datacard.component.html',
  styleUrls: ['./datacard.component.css']
})
export class DatacardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
