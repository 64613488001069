import { Component, OnInit, ViewChild, TemplateRef, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoginResp, BalanceResp } from 'src/app/enums/apiResponse';
import { ApiService } from 'src/app/services/apiservices.service';
import { Location } from '@angular/common';

import { ApidataService } from 'src/app/services/apidata.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApisessionService } from 'src/app/services/apisession.service';
import { RespCode, APIUrl, SessionVar, OpTypes } from 'src/app/enums/emums';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'aditya-desktopheader',
  templateUrl: './desktopheader.component.html',
  styleUrls: ['./desktopheader.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopheaderComponent implements OnInit, OnChanges {
  Domain = '';
  isMobile = false;
  IsLoading = false;
  IsLoggedIn = false;
  LoginUserName = '';
  LoginUserBalance = '0';
  LoginUserUBalance = '0';
  LoginPopUp: BsModalRef;
  LoginForm: FormGroup;
  LoginResp: LoginResp;
  IsLoginSubmitted = false;
  SignUpForm: FormGroup;
  SignUpResp: any;
  handelClick = true
  IsSignUpSubmitted = false;
  gettingBalanceSpinClass = "fa fa-refresh";
  @Input() LoginDetail: any
  LoginData: any
  @ViewChild('modelSignIn') modelSignIn: any;
  @ViewChild('myModal') myModal: any;
  isWalletToWallet = false;
  IsAdvertisement = false;
  isHeader = false;
  isMenu = false;
  headerName = '';
  headerSubscription: any;
  mobileScreenSubscription: any
  modalShow = true;
  constructor(private modalService: BsModalService,
    private apiServices: ApiService,
    private apiData: ApidataService,
    private authService: AuthService,
    private apiSession: ApisessionService, private toastr: ToastrService,
    private location: Location, private cdr: ChangeDetectorRef, private router: Router,
    private fb: FormBuilder) { }
  
  ngOnChanges(changes: SimpleChanges) {
    this.apiData.getObjectData().subscribe((data: any) => {
      this.LoginDetail = data;
      this.cdr.detectChanges()
    })
  }
  ngOnInit() {
    this.apiData.isMenuChange$.subscribe((value) => {
      this.isMenu = value;
      this.updateView()
      // this.cdr.detectChanges();
    })
    this.apiData.getHeaderStateObservable().subscribe(state => {
      this.headerName = state;
      this.updateView();
      console.log(this.headerName);
    });
    this.apiData.ScreenSize.subscribe((value) => {
      if (value <= 1024) {
        this.isMenu = false
        // this.cdr.detectChanges();
      } else {
        // this.headerSubscription = this.apiData.isHeaderChange$.subscribe((value) => {
        //   this.headerName = value;
        //   this.cdr.detectChanges();
        // })
        this.apiData.getHeaderStateObservable().subscribe(state => {
          this.headerName = state;
          this.updateView();
        });
      }
      })
    this.mobileScreenSubscription = this.apiData.isMobileScreen$.subscribe((value) => {
      this.isMobile = value;
      localStorage.setItem('isMobile', value.toString());
      this.cdr.detectChanges();
    })
    this.Domain = APIUrl.Domain;
    this.login();

    if (this.apiData.subsLogin == undefined) {
      this.apiData.subsLogin = this.apiData.invokeHeaderLoginFunction
        .subscribe((name: string) => {
          this.login();
        });
    }
    if (this.apiData.subsLoader == undefined) {
      this.apiData.subsLoader = this.apiData.invokeHeaderLoaderFunction
        .subscribe((name: string) => {
          this.loaderToggle();
        });
    }

    if (this.apiData.subsBalanceChange == undefined) {

      this.apiData.subsBalanceChange = this.apiData.invokeHeaderBalanceFunction
        .subscribe((name: string) => {
          this.getWalletbalance();
        });
    }
    this.IsAdvertisement = JSON.parse(localStorage.getItem(SessionVar.OperatorList)).assignedOpTypes.filter((x: any) => x.serviceID == OpTypes.Advertisement).length > 0;
  }
  removeTabFromLocal() {
    localStorage.removeItem('tabId')
  }

  changeMenuLayout(value: any) {
    this.apiData.setHeaderState(value);
    this.apiData.isMenuChange$.next(true); 
    this.updateView()
    // this.cdr.detectChanges();
  }

  backToHome() {
    this.apiData.isMobileScreen$.next(true);
    this.apiData.isMenuChange$.next(false);
    this.updateView()
    const currentUrl: string = this.location.path();
    if (currentUrl.includes('user') && this.isMobile === true) {
      this.router.navigate(['/#'])
      setTimeout(() => {
        this.apiData.isMobileScreen$.next(true);
        this.apiData.innerMenu$.next(true);
      }, 100)
      this.updateView()
    } else {
      
    }
    this.updateView()
  }
  openForgotModal() {
    this.modalShow = !this.modalShow;
  }
  gotoLogin() {
    localStorage.setItem('tabId', JSON.stringify(0))
    this.apiData.gotoPage('login.html');
    localStorage.setItem('isMobile', 'false')
  }
  gotosignup() {
    localStorage.setItem('tabId', JSON.stringify(0))
    this.apiData.gotoPage('signup.html');
    localStorage.setItem('isMobile', 'false')
  }
  loaderToggle() {
    if (this.IsLoading) {
      this.IsLoading = false;
    }
    else
      this.IsLoading = true;
  }

  login() {
    if (this.authService.IsAuth()) {
      this.LoginUserName = this.authService.getUserName();
      this.getWalletbalance();
    }
  }
  logout() {
    if (confirm('Are you sure to logout')) {
      this.authService.logout();
      this.apiSession.Logout().subscribe();
      this.IsLoggedIn = false;
      this.LoginUserBalance = '0';
      this.LoginUserUBalance = '0';

      this.LoginDetail.IsLoggedIn = false;
      this.LoginDetail.LoginUserName = '';
      this.LoginDetail.LoginUserBalance = '0';
      this.LoginDetail.LoginUserUBalance = '0';
      this.toastr.success("LogOut successfully", "Success")
      window.location.href = '#';
    }
  }
  getWalletbalance() {
    this.gettingBalanceSpinClass = "fa fa-refresh fa-spin";
    var BalanceResp: BalanceResp;
    this.apiSession.GetBalance().subscribe(resp => {
      BalanceResp = resp;
      this.isWalletToWallet = resp.isWalletToWallet;
      if (BalanceResp != undefined) {
        if (BalanceResp.statuscode == RespCode.Success) {
          this.LoginDetail.LoginUserBalance = BalanceResp.data.balance.toString()
          this.LoginDetail.LoginUserUBalance = BalanceResp.data.uBalance.toString()
        }
      }
      this.gettingBalanceSpinClass = "fa fa-refresh";
    })
  }
  updateView() {
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }
  ngOnDestroy() {
    this.headerSubscription.unsubscribe();
    this.mobileScreenSubscription.unsubscribe();
  }
}
